/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Else, If, Then } from 'react-if';
import dynamic from 'next/dynamic';
import { observer } from 'mobx-react-lite';
import cc from 'classcat';
import dayjs from 'dayjs';
import styles from './card.module.sass';
import { Icon } from '../Icon';
import { Badge } from '../Badge';
import { formatter } from '../../utils/formatter';
// import { declOfNum } from '../../utils/declOfNum';
import { Anchor } from '../Text';
import { isClient } from '../../utils/isClient';
import { useFavorites } from '../../providers/RootStoreProvider';
import { useEmblaSlider } from '../../hooks/useEmblaSlider';
import { TravelTime } from '../TravelTime';
import { CruiseRoute } from '../CruiseRoute';
import { YaAndGaMainPage } from '../../lib/YaAndGaHelper';
import { Modal } from '../Modal';
import { ReworkUrl } from '../../utils/reworkUrl';
import { IconSVG } from '../SpriteSVG';
import { Image } from '../Image/Image';
import { formatDateEnd, formatDateStart } from '../../utils/useFormatDate';
import { yandexDataLayer } from '../../lib/yandexDataLayer';
import { useSearchStore } from '../../providers/SearchStoreProvider';
import { isNewYear } from '../../utils/isBlackFridayCheck';

const CruiseRouteMap = dynamic(() => import('../CruiseRouteMap'), {
  ssr: false,
});

const categories = {
  1: {
    classCategoryReady: 'eco',
    title: 'Эконом',
  },
  2: {
    classCategoryReady: 'standart',
    title: 'Стандарт',
  },
  3: {
    classCategoryReady: 'comfort',
    title: 'Комфорт',
  },
  6: {
    classCategoryReady: 'lux',
    title: 'Люкс',
  },
  7: {
    classCategoryReady: 'premium',
    title: 'Премиум',
  },
};

const categoriesStr = {
  Эконом: {
    classCategoryReady: 'eco',
    title: 'Эконом',
  },
  Стандарт: {
    classCategoryReady: 'standart',
    title: 'Стандарт',
  },
  Комфорт: {
    classCategoryReady: 'comfort',
    title: 'Комфорт',
  },
  Люкс: {
    classCategoryReady: 'lux',
    title: 'Люкс',
  },
  Премиум: {
    classCategoryReady: 'premium',
    title: 'Премиум',
  },
};

const preparingImages = images => {
  if (!images?.length) return [];
  return images;
};

export const Card = observer(
  ({
    index,
    id,
    title = '',
    link,
    price,
    route,
    shipId,
    cashback,
    places,
    category,
    categoryTitle,
    popdir,
    startDate,
    endDate,
    start,
    end,
    oldPrice,
    isFavorities,
    days,
    night,
    specialNote,
    discounts,
    categoryString,
    isSpecialOffer,
    specialOfferValue,
    company,
    sea,
    viewed,
    images = [],
    isMobile,
    isShipCard,
    // countDesc,
    seat_count,
    caute_count,
    blackFriday,
    // shopingBadge,
    // snowBadge,
    onClick,
    tour,
    turField,
    recomend,
    popularCruises,
    metriks,
    url,
    direction,
    ownerId,
  }) => {
    const [activeImage, setActiveImage] = useState(0);
    const [routeModalIsOpen, setRouteModalIsOpen] = useState(false);
    const favorite = useFavorites();
    const { ref, current } = useEmblaSlider();

    const dateStart = formatDateStart(startDate, endDate);
    const dateEnd = formatDateEnd(endDate);

    const dateOptions = {
      weekday: 'short',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'long',
    };

    const preparedFavoriteData = {
      id,
      title,
      price,
      route,
      places,
      images,
      category: categoryString ? category : categories[category]?.title,
      startDate,
      endDate,
      special_note: specialNote,
      kr_days: days,
      ship_id: shipId,
      isSea: Boolean(sea),
    };

    const isFavorite = favorite.checkById(id);

    const onFavorite = e => {
      favorite.toggle(preparedFavoriteData);
      e.stopPropagation();
    };

    const urlPopdir = direction ? `?${direction}` : '';

    const str = categoryString ? categoriesStr : categories;
    let href = sea
      ? `/sea_cruise/${link}${urlPopdir}`
      : `/cruise/${link}${urlPopdir}`;
    href = isShipCard ? `/ships/${link}${urlPopdir}` : href;

    const imageLinks = !isShipCard ? preparingImages(images) : images;
    const allowedImagesNumber = 5;
    const imagesLeft = imageLinks.length - allowedImagesNumber;
    const totalZones =
      imageLinks.length > allowedImagesNumber
        ? allowedImagesNumber
        : imageLinks.length;

    return (
      <div
        className={styles.wrapper}
        onClick={() =>
          yandexDataLayer('click', {
            id,
            name: `${title} с ${dateStart} по ${dateEnd}`,
            brand: company,
            category: `${
              sea ? 'Море' : 'Река'
            }/${categoryTitle}/${title}/${route}`,
            list: popdir?.caption,
            position: index + 1,
            price,
            quantity: 1,
          })
        }
      >
        {isClient && !isShipCard && (
          <button
            className={styles.favorite}
            onClick={onFavorite}
            title="Корзина"
          >
            {!isFavorite ? (
              <IconSVG
                name={url === 'black_friday' ? 'common/cartBf' : 'common/cart'}
                width={36}
                height={36}
                title="Корзина"
                onClick={() => {
                  yandexDataLayer('add', {
                    id,
                    name: `${title} с ${dateStart} по ${dateEnd}`,
                    brand: company,
                    category: `${
                      sea ? 'Море' : 'Река'
                    }/${categoryTitle}/${title}/${route}`,
                    list: popdir?.caption,
                    position: index + 1,
                    price,
                    quantity: 1,
                  });
                }}
              />
            ) : (
              <IconSVG
                name={
                  url === 'black_friday'
                    ? 'common/cartBf-active'
                    : 'common/cart-active'
                }
                width={24}
                height={24}
                title="Добавлено в корзину"
                onClick={() =>
                  yandexDataLayer('remove', {
                    id,
                    name: `${title} с ${dateStart} по ${dateEnd}`,
                    brand: company,
                    category: `${
                      sea ? 'Море' : 'Река'
                    }/${categoryTitle}/${title}/${route}`,
                    list: popdir?.caption,
                    position: index + 1,
                    price,
                    quantity: 1,
                  })
                }
              />
            )}
          </button>
        )}

        <div>
          {!isShipCard && (
            <div itemScope itemType="http://schema.org/SaleEvent">
              <meta
                itemProp="startDate"
                content={new Date(
                  dayjs(startDate).format('YYYY,MM,DD'),
                ).toLocaleDateString('ru-Ru', dateOptions)}
              />
              <meta
                itemProp="endDate"
                content={new Date(
                  dayjs(endDate).format('YYYY,MM,DD'),
                ).toLocaleDateString('ru-Ru', dateOptions)}
              />
              <meta itemProp="name" content={title} />
              <meta itemProp="description" content="Круиз.онлайн" />
              <meta
                itemProp="eventAttendanceMode"
                content="https://schema.org/OnlineEventAttendanceMode"
              />
              <meta
                itemProp="eventStatus"
                content="https://schema.org/EventScheduled"
              />
              <meta itemProp="url" content={`/cruise/${id}`} />
              <meta itemProp="image" content={images && images[0]?.srcset} />
              <meta itemProp="performer" content={title} />
              <div
                itemScope
                itemType="http://schema.org/Place"
                itemProp="location"
              >
                <meta itemProp="logo" content="Круиз.онлайн" />
                <meta itemProp="name" content={title} />

                <div
                  itemProp="address"
                  itemScope
                  itemType="http://schema.org/PostalAddress"
                >
                  <meta itemProp="url" content={`/cruise/${id}`} />
                  <meta
                    itemProp="streetAddress"
                    content={`Галактионовская ул., 157`}
                  />
                  <meta itemProp="addressLocality" content={`Самара`} />
                  <meta itemProp="postalCode" content={`443041`} />
                  <meta itemProp="addressRegion" content={'RU'} />
                  <meta itemProp="addressCountry" content={'RU'} />
                </div>
              </div>
              <div
                itemProp="offers"
                itemScope
                itemType="https://schema.org/Offer"
              >
                <meta itemProp="price" content={price} />
                <meta itemProp="priceCurrency" content="RUB" />
                <meta itemProp="url" content={`/cruise/${id}`} />
                <meta
                  itemProp="availability"
                  href="https://schema.org/InStock"
                />
                <meta
                  itemProp="validFrom"
                  content={new Date(
                    dayjs(startDate).format('YYYY,MM,DD'),
                  ).toLocaleDateString('ru-Ru', dateOptions)}
                />
              </div>
              <div itemProp="organizer" content="Круиз.онлайн">
                <meta itemProp="url" content="/" />
              </div>
            </div>
          )}
          <div className={styles.main}>
            <a
              href={href}
              ref={ref}
              className={styles.link}
              rel="noreferrer"
              onClick={
                onClick
                  ? () => {
                      YaAndGaMainPage('click_choose_cabin');
                      yandexDataLayer('click', {
                        id,
                        name: `${title} с ${dateStart} по ${dateEnd}`,
                        brand: company,
                        category: `${
                          sea ? 'Море' : 'Река'
                        }/${categoryTitle}/${title}/${route}`,
                        list: popdir?.caption,
                        position: index + 1,
                        price,
                        quantity: 1,
                      });
                    }
                  : () =>
                      yandexDataLayer('click', {
                        id,
                        name: `${title} с ${dateStart} по ${dateEnd}`,
                        brand: company,
                        category: `${
                          sea ? 'Море' : 'Река'
                        }/${categoryTitle}/${title}/${route}`,
                        list: popdir?.caption,
                        position: index + 1,
                        price,
                        quantity: 1,
                      })
              }
            >
              <header className={styles.header}>{title || '-'}</header>
            </a>
            <div className={styles.pic}>
              <If condition={images?.length < 2}>
                <Then>
                  {imageLinks.slice(0, allowedImagesNumber).map((i, index) => (
                    <a
                      href={href}
                      className={styles.embla}
                      ref={ref}
                      rel="noreferrer"
                      onClick={
                        onClick ??
                        (() => {
                          yandexDataLayer('click', {
                            id,
                            name: `${title} с ${dateStart} по ${dateEnd}`,
                            brand: company,
                            category: `${
                              sea ? 'Море' : 'Река'
                            }/${categoryTitle}/${title}/${route}`,
                            list: popdir?.caption,
                            position: index + 1,
                            price,
                            quantity: 1,
                          });
                        })
                      }
                    >
                      <Image
                        key={index}
                        src={ReworkUrl(i.src)}
                        srcSet={ReworkUrl(i.srcset)}
                        className={cc({ [styles.blackout]: viewed })}
                        alt={title}
                        loading="lazy"
                        style={{
                          display: index === activeImage ? 'block' : 'none',
                        }}
                      />
                    </a>
                  ))}
                </Then>
                <Else>
                  <If condition={isMobile}>
                    <Then>
                      <a
                        href={href}
                        className={styles.embla}
                        ref={ref}
                        rel="noreferrer"
                        onClick={
                          onClick ??
                          (() =>
                            yandexDataLayer('click', {
                              id,
                              name: `${title} с ${dateStart} по ${dateEnd}`,
                              brand: company,
                              category: `${
                                sea ? 'Море' : 'Река'
                              }/${categoryTitle}/${title}/${route}`,
                              list: popdir?.caption,
                              position: index + 1,
                              price,
                              quantity: 1,
                            }))
                        }
                      >
                        <div className={styles.emblaContainer}>
                          {imageLinks
                            .slice(0, allowedImagesNumber)
                            .map((i, index) => (
                              <div
                                key={index}
                                className={cc([
                                  styles.emblaSlide,
                                  { [styles.blackout]: viewed },
                                ])}
                              >
                                <Image
                                  src={sea ? `${i.src}` : ReworkUrl(i.src)}
                                  srcSet={
                                    sea ? `${i.srcset}` : ReworkUrl(i.srcset)
                                  }
                                  alt={title}
                                  loading={index === 0 ? 'eager' : 'lazy'}
                                />
                              </div>
                            ))}
                        </div>
                      </a>
                    </Then>
                    <Else>
                      <div
                        style={{ height: '100%' }}
                        className={cc({ [styles.blackout]: viewed })}
                      >
                        {imageLinks
                          .slice(0, allowedImagesNumber)
                          .map((i, index) => (
                            <Image
                              key={index}
                              src={recomend ? `${i.src}` : ReworkUrl(i.src)}
                              srcSet={
                                recomend ? `${i.srcset}` : ReworkUrl(i.srcset)
                              }
                              className={cc({ [styles.blackout]: viewed })}
                              alt={i.description ? i.description : title}
                              title={i.description ? i.description : title}
                              loading="lazy"
                              style={{
                                display:
                                  index === activeImage ? 'block' : 'none',
                              }}
                            />
                          ))}
                      </div>
                    </Else>
                  </If>
                </Else>
              </If>
              <div className={styles.badges}>
                <div className={styles.bagesBlock}>
                  {isNewYear() && (
                    <Badge view={'newYear'} size="smUltra" icon={'newYear'}>
                      новогодний кешбэк
                    </Badge>
                  )}
                  {!sea && category && (
                    <Badge
                      size="smUltra"
                      view={str[category]?.classCategoryReady}
                      icon={str[category]?.classCategoryReady}
                    >
                      {str[category]?.title}
                    </Badge>
                  )}
                </div>
                {!sea && tour !== 0 && tour !== undefined && tour !== '' && (
                  <Badge view="tour" size="smUltra" icon="tour">
                    {turField || 'Круиз + тур'}
                  </Badge>
                )}
              </div>
              <If condition={totalZones > 1}>
                <Then>
                  <If condition={!isMobile}>
                    <Then>
                      <a
                        href={href}
                        className={styles.zones}
                        onMouseLeave={() => setActiveImage(0)}
                        rel="noreferrer"
                      >
                        {new Array(totalZones).fill(0).map((_, index) => (
                          <div
                            key={index}
                            className={styles.zone}
                            onMouseOver={() => setActiveImage(index)}
                            onFocus={() => setActiveImage(index)}
                          />
                        ))}
                      </a>
                    </Then>
                  </If>
                  <div className={styles.dots}>
                    {new Array(totalZones).fill(0).map((_, index) => (
                      <span
                        key={index}
                        className={cc([
                          styles.dot,
                          {
                            [styles.active]:
                              index === (isMobile ? current : activeImage),
                          },
                        ])}
                      />
                    ))}
                  </div>
                  <If
                    condition={
                      (isMobile ? current : activeImage) + 1 ===
                        allowedImagesNumber && imagesLeft > 0
                    }
                  >
                    <Then>
                      <div className={styles.morePhotos}>
                        Ещё {imagesLeft} фото
                      </div>
                    </Then>
                  </If>
                </Then>
              </If>
              {viewed && (
                <div className={styles.viewed}>
                  <Badge view="white" size="smUltra">
                    Просмотрено
                  </Badge>
                </div>
              )}
            </div>
            <div className={styles.body}>
              <CruiseRoute
                simpleView
                limit={2}
                items={route}
                noReturn={start !== end}
                onShowMore={() => {
                  setRouteModalIsOpen(true);
                }}
              />
              {!isShipCard && dateStart && dateEnd && (
                <TravelTime
                  start={dateStart}
                  end={dateEnd}
                  days={days}
                  nights={night}
                />
              )}

              {specialNote &&
                !specialNote?.includes('15%') &&
                specialNote !== 'Распродажа зимних круизов!' && (
                  <div className={styles.bonus}>
                    <Icon
                      icon={
                        <IconSVG name={'promo/note'} width={24} height={24} />
                      }
                      rightGap={8}
                      title="Спец предложение"
                    />
                    <p>{specialNote}</p>
                  </div>
                )}
              {isSpecialOffer !== 0 && isSpecialOffer && (
                <div className={styles.bonus}>
                  <Icon
                    icon={
                      <IconSVG name={'promo/present'} width={24} height={24} />
                    }
                    rightGap={8}
                    title="Спец предложение"
                  />
                  <p>Спецпредложение -{specialOfferValue}%</p>
                </div>
              )}
              <>
                {discounts != null &&
                  Object.values(discounts).map(
                    discount =>
                      discount != null && (
                        <div
                          key={discount.value}
                          className={styles.modalDiscounts}
                        >
                          <Icon
                            icon={
                              <IconSVG
                                name={'promo/sale'}
                                width={24}
                                height={24}
                              />
                            }
                            rightGap={8}
                            title="Скидка"
                          />
                          {discount.value}% {discount.alias}
                        </div>
                      ),
                  )}
              </>
              {isNewYear() && (
                <div className={styles.bonus}>
                  <Icon
                    icon={
                      <IconSVG
                        name={'promo/violetPresent'}
                        width={24}
                        height={24}
                      />
                    }
                    rightGap={8}
                    title="Спец предложение"
                  />

                  <p>Выигрывайте кешбэк до 20%</p>
                </div>
              )}
              {/* {false && ( */}
              {/*   <div className={styles.bonus}> */}
              {/*     <Icon icon={rubleIcon} rightGap={8} title="Иконка рубля" /> */}
              {/*     <p> */}
              {/*       Цена вырастет через{' '} */}
              {/*       <span> */}
              {/*         {beforeRise} {declOfNum(places, ['день', 'дня', 'дней'])} */}
              {/*       </span> */}
              {/*     </p> */}
              {/*   </div> */}
              {/* )} */}
              {cashback === 1 && (
                <div className={styles.bonus}>
                  <Icon
                    icon={
                      <IconSVG name={'promo/cashback'} width={24} height={24} />
                    }
                    rightGap={8}
                    title="Иконка кэшбека"
                  />
                  <p>Кэшбэк 20%</p>
                </div>
              )}
              {isShipCard && (
                <>
                  <div className={styles.bonus}>
                    <Icon
                      icon={
                        <IconSVG
                          name={'promo/caute_count'}
                          width={24}
                          height={24}
                        />
                      }
                      rightGap={8}
                      title="Иконка кэшбека"
                    />
                    <p>Общее количество кают: {caute_count}</p>
                  </div>
                  <div className={styles.bonus}>
                    <Icon
                      icon={
                        <IconSVG
                          name={'promo/seat_count'}
                          width={24}
                          height={24}
                        />
                      }
                      rightGap={8}
                      title="Иконка кэшбека"
                    />
                    <p>Вместимость (человек): {seat_count}</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className={styles.company}>
          <Text variant="text-8" color="darken">
            Туроператор {company}
          </Text>
        </div> */}
        <footer className={styles.footer}>
          {!isShipCard && (
            <div className={styles.footerPrice}>
              {price < 200 ? (
                <span className={styles.priceInfo}>цена по запросу</span>
              ) : (
                <span className={styles.price}>
                  {`от ${formatter(price)} ₽`}
                </span>
              )}
              {oldPrice !== price && !isFavorities && (
                <div className={styles.price}>
                  <span className={styles.oldPrice}>
                    {sea && !price && oldPrice < 200
                      ? 'цена по запросу'
                      : `от ${formatter(oldPrice)} ₽`}
                  </span>
                </div>
              )}
            </div>
          )}
          {!isShipCard ? (
            <div>
              <a
                href={href}
                className={url === 'black_friday' ? styles.btnBf : styles.btn}
                onClick={
                  onClick ??
                  (() => [
                    yandexDataLayer('click', {
                      id,
                      name: `${title} с ${dateStart} по ${dateEnd}`,
                      brand: company,
                      category: `${
                        sea ? 'Море' : 'Река'
                      }/${categoryTitle}/${title}/${route}`,
                      list: popdir?.caption,
                      position: index + 1,
                      price,
                      quantity: 1,
                    }),
                    metriks &&
                      isClient &&
                      window.ym(35441960, 'reachGoal', metriks),
                  ])
                }
              >
                {popularCruises ? 'Подробнее' : 'Выбор каюты'}
              </a>
            </div>
          ) : (
            <Anchor
              decoration="none"
              className={styles.shipButton}
              href={`/ships/${link}`}
              onClick={
                onClick ?? (() => YaAndGaMainPage('click_more_you_might_like'))
              }
            >
              <div className={styles.btn}>Подробнее</div>
            </Anchor>
          )}
        </footer>
        <Modal
          size={'xmd'}
          isOpen={routeModalIsOpen}
          onOpen={setRouteModalIsOpen}
          title={title || '-'}
          // onCloseAction={onCloseAction}
        >
          <div className={styles.modalWrapper}>
            {!isShipCard && dateStart && dateEnd && (
              <div className={styles.modalTravelTime}>
                <TravelTime
                  start={dateStart}
                  end={dateEnd}
                  days={days}
                  nights={night}
                />
              </div>
            )}
            <div className={styles.modalMap}>
              {routeModalIsOpen && <CruiseRouteMap />}
            </div>
            <CruiseRoute items={route} noReturn={start !== end} />
            {discounts != null &&
              Object.values(discounts).map(
                discount =>
                  discount != null && (
                    <div key={discount.value} className={styles.modalDiscount}>
                      <Icon
                        icon={
                          <IconSVG name={'promo/sale'} width={20} height={20} />
                        }
                        rightGap={8}
                        title="Скидка"
                      />
                      {discount.value}% {discount.alias}
                    </div>
                  ),
              )}
            {cashback === 1 && (
              <div className={styles.modalBonus}>
                <Icon
                  icon={
                    <IconSVG name={'promo/cashback'} width={24} height={24} />
                  }
                  rightGap={8}
                  title="Иконка кэшбека"
                />
                <p>Кэшбэк 20%</p>
              </div>
            )}
            {specialNote && (
              <div className={styles.modalBonus}>
                <Icon
                  icon={<IconSVG name={'promo/note'} width={24} height={24} />}
                  rightGap={8}
                  title="Спец предложение"
                />
                <p>{specialNote}</p>
              </div>
            )}
            <footer className={styles.modalFooter}>
              {!isShipCard && (
                <div className={styles.footerPrice}>
                  <span className={styles.price}>
                    {sea && price < 200
                      ? 'цена по запросу'
                      : `от ${formatter(price)} ₽`}
                  </span>
                  {oldPrice !== price && !isFavorities && (
                    <div className={styles.price}>
                      <span className={styles.oldPrice}>
                        {sea && !price && oldPrice < 200
                          ? 'цена по запросу'
                          : `от ${formatter(oldPrice)} ₽`}
                      </span>
                    </div>
                  )}
                </div>
              )}
              {!isShipCard && (
                <div>
                  <a
                    href={href}
                    className={
                      url === 'black_friday' ? styles.btnBf : styles.btn
                    }
                    onClick={
                      onClick ??
                      (() => [
                        YaAndGaMainPage('click_choose_cabin'),
                        metriks &&
                          isClient &&
                          window.ym(35441960, 'reachGoal', metriks),
                      ])
                    }
                  >
                    {popularCruises ? 'Подробнее' : 'Выбор каюты'}
                  </a>
                </div>
              )}
            </footer>
          </div>
        </Modal>
      </div>
    );
  },
);
